import { Alert } from "antd"


const Error = ({msg}) => {
  return (
    <span
      className="!py-2 dark:bg-jacarta-800 dark:border-jacarta-700 dark:!text-white px-3 rounded bg-[#fff1f2]"
    >{msg}</span>
  )
}

export default Error